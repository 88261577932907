<template>
  <Registration />
</template>

<script>
import Registration from '@/modules/registration'

export default {
  components: {
    Registration
  }
}
</script>
