<template>
  <div class="d-flex flex-column justify-center mt-8">
    <v-alert
      dense
      outlined
      type="error"
    >
      {{ $t('registration.error.alert') }}
    </v-alert>

    <v-btn
      color="primary"
      to="/auth/login"
    >
      {{ $t('registration.error.back') }}
    </v-btn>
  </div>
</template>

<script>
export default {

}
</script>
