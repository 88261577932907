<template>
  <div class="px-4 mt-4 text-center px-md-0">
    <h1 class="mb-4 display-1 font-weight-black">
      Hallo {{ $auth.user.name }}!
    </h1>
    <p class="mb-8 text-body-2">
      Schön Sie bei uns begrüßen zu dürfen! Um Ihr BOTTIMMO individuell einzurichten, benötigen wir nur wenige Angaben:
    </p>
    <v-container
      class="pa-0"
      :style="{'width': $vuetify.breakpoint.smAndDown ? '100%':'1000px'}"
    >
      <CrmSettings
        hide-help
        slim-mode
        @isFilterSelected="handleFilterSelected"
      />
      <div class="d-flex justify-center justify-md-end mx-4">
        <v-checkbox
          v-if="!isFilterSelected"
          v-model="skipStep"
          label="Diesen Schritt überspringen."
        />
      </div>
    </v-container>

    <v-btn
      class="w-full mt-4 mb-2 primary"
      :style="{'width': $vuetify.breakpoint.smAndDown ? '100%':'300px'}"
      :disabled="!canSubmit"
      @click="submit"
    >
      Einrichtung abschließen
    </v-btn>
  </div>
</template>

<script>
import brandingMixin from '@/mixins/branding'
import CrmSettings from '@/modules/settings/crmSettings/CrmSettings.vue'
import COMPLETE_ONBOARDING_STEP_CRM_CONFIG from './queries/CompleteOnboardingStepCRMConfig.gql'
import bus, { eventNames } from '@/lib/eventBus'
export default {
  components: {
    CrmSettings
  },
  mixins: [brandingMixin],
  data () {
    return {
      skipStep: false,
      isFilterSelected: false
    }
  },
  computed: {
    canSubmit () {
      return this.skipStep || this.isFilterSelected
    }
  },
  methods: {
    handleFilterSelected (value) {
      this.isFilterSelected = value
      if (value) {
        this.skipStep = false
      }
    },
    async submit () {
      try {
        const { data: { completeOnboardingStepCRMConfig } } = await this.$apollo.mutate({
          mutation: COMPLETE_ONBOARDING_STEP_CRM_CONFIG,
          variables: {}
        })
        if (completeOnboardingStepCRMConfig.status === 'SUCCESS') {
          this.$emit('completed')
        }
        if (completeOnboardingStepCRMConfig.status === 'ERROR') {
          bus.$emit(
            eventNames.SHOW_SNACKBAR,
            {
              color: 'error',
              text: 'Es gab leider ein technisches Problem beim speichern Ihrer Daten. Wir kümmern uns darum!'
            }
          )
        }
      } catch (error) {
        bus.$emit(
          eventNames.SHOW_SNACKBAR,
          {
            color: 'error',
            text: 'Es gab leider ein technisches Problem beim speichern Ihrer Daten. Wir kümmern uns darum!'
          }
        )
      }
    }
  }
}
</script>
